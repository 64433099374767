// @ts-nocheck

import axios, { AxiosInstance } from 'axios';


const webApp = window?.Telegram?.WebApp;

export const API_URL: string = `${process.env.REACT_APP_BACKEND_API}`;
export const API_SERVER_WEBSOCKET: string = `${process.env.REACT_APP_SERVER_WEBSOCKET}`;

const $api: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

$api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      !error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const token = localStorage.getItem('access_token');
        localStorage.removeItem('access_token');
        const response = await axios.get(`${API_URL}/auth/refresh?token=${token}`, {
          withCredentials: true,
        });
        localStorage.setItem('access_token', response.data.access_token);
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;
        return api.request(originalRequest);
      } catch (e) {
        console.log('Не авторизован. error in interceptors.response error callback -->', e);
      }
    }
    throw error;
  }
)

$api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    return error;
  }
)


export default $api;