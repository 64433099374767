import style from './TransactionInfo.module.scss';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Router
import { useNavigate, useParams } from 'react-router-dom';


const TransactionInfo = () => {
    const navigate = useNavigate();
    let { cryptoName, transactionId } = useParams();

    const transactionInfo = {
        'action': 'add',
        'sum': 2723,
        'sumCrypto': 10,
        'date': '08.11.2024',
        'time': '12:48',
        'status': 'success',
    }

    return (
        <div className={style.transaction_info}>
            <BackButton onClick={() => navigate(-1)} />
            <h2>Пополнение</h2>
            <div className={style.transaction_info__crypto_sum}>
                <p>+{transactionInfo.sumCrypto} {cryptoName?.toUpperCase()}</p>
            </div>
            <div className={style.transaction_info__rubble_sum}>
                <p>+{transactionInfo.sum} ₽</p>
            </div>
            <div className={style.transaction_info__date}>
                <p>{transactionInfo.date} в {transactionInfo.time}</p>
            </div>
            <div className={style.transaction_info__status}>
                <p className={style.transaction_info__status_header}>Статус</p>
                <p className={style.transaction_info__status_cnt}>{transactionInfo.status === 'success' ? 'Успешно' : 'Ошибка'}</p>
            </div>
        </div>
    )
}

export default TransactionInfo;