
import $api, { API_URL } from '../http';
import { TransactionFormData } from '../redux/transaction/types';


export default class TransactionService {
    static async transactionCreateBitcoin(formData: TransactionFormData) {
        const res = await $api.post(`${API_URL}/bitcoin/transaction/create`, formData)
        .then((res) => {
            if (res.data) {
                return res.data;
            } else {
                return JSON.parse(res.request.response)
            }
        })
        .catch((err) => {
            return err.response.data;
        });
        return res;
    }
    static async transactionCreateTon(formData: TransactionFormData) {
        const res = await $api.post(`${API_URL}/ton/transaction/create`, formData)
        .then((res) => {
            if (res.data) {
                return res.data;
            } else {
                return JSON.parse(res.request.response)
            }
        })
        .catch((err) => {
            return err.response.data;
        });
        return res;
    }
}