import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { TransactionSliceState } from './types';
import { requestTransactionCreateBitcoin, requestTransactionCreateTon } from './asyncActions';


const initialState: TransactionSliceState = {
    message: '',
    status: Status.CALM as Status,
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setNullTransaction(state) {
            state.message = '';
            state.status = Status.CALM;
        }
    },
    extraReducers: (builder) => {
        // Bitcoin Create Transaction
        builder.addCase(requestTransactionCreateBitcoin.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestTransactionCreateBitcoin.fulfilled, (state, action) => {
            if (action.payload.detail === 'Транзакция успешно создана') {
                state.status = Status.SUCCESS;
                state.message = action.payload.detail;
            } else {
                state.status = Status.ERROR;
                state.message = action.payload.detail;
            }
        })
        builder.addCase(requestTransactionCreateBitcoin.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при создании транзакции Bitcoin';
        });
        // Ton Create Transaction
        builder.addCase(requestTransactionCreateTon.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestTransactionCreateTon.fulfilled, (state, action) => {
            if (action.payload.detail === 'Транзакция успешно создана') {
                state.status = Status.SUCCESS;
                state.message = action.payload.detail;
            } else {
                state.status = Status.ERROR;
                state.message = action.payload.detail;
            }
        })
        builder.addCase(requestTransactionCreateTon.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при создании транзакции Ton';
        });
    }
})

export const { setNullTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;