import style from './Navbar.module.scss';

// Router
import { Link, useLocation } from 'react-router-dom';

// Images
import walletImg from '../../assets/img/wallet_gray.png';
import walletActiveImg from '../../assets/img/wallet_blue.png';

import profileImg from '../../assets/img/profile_gray.png';
import profileActiveImg from '../../assets/img/profile_blue.png';


const Navbar = () => {
    const { pathname } = useLocation();

    return (
        <div className={style.navbar}>
            <Link
                to={'/home'}
                className={`${style.navbar__img_block} ${pathname === '/home' || pathname.includes('/home/card') || pathname.includes('transaction') || pathname.includes('currency-info') || pathname.includes('add-money') ? style.active : ''}`}
            >
                <img
                    src={pathname === '/home' || pathname.includes('/home/card') || pathname.includes('transaction') || pathname.includes('currency-info') || pathname.includes('add-money') ? walletActiveImg : walletImg}
                    alt="wallet-img"
                />
                <p>Ваш кошелёк</p>
            </Link>
            <Link
                to={'/home/profile'}
                className={`${style.navbar__img_block} ${pathname === '/home/profile' && style.active}`}
            >
                <img src={pathname === '/home/profile' ? profileActiveImg : profileImg} alt="profile-img" />
                <p>Профиль</p>
            </Link>
        </div>
    )
}

export default Navbar;