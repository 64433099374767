import { createAsyncThunk } from '@reduxjs/toolkit';

import WalletsService from '../../services/WalletsService';


export const requestGetWalletTon = createAsyncThunk('/ton/wallet/ton', async () => {
    const response = await WalletsService.getWalletTon();
    return response;    
})

export const requestGetWalletBtc = createAsyncThunk('/bitcoin/wallet/bitcoin', async () => {
    const response = await WalletsService.getWalletBtc();
    return response;    
})

export const requestGetWalletUsdt = createAsyncThunk('/usdt/wallet/usdt', async () => {
    const response = await WalletsService.getWalletUsdt();
    return response;    
})