import { createSlice } from '@reduxjs/toolkit';
import { RatesSliceState } from './types';
import { Status } from '../../utils/utils';


const initialState: RatesSliceState = {
    statusRates: Status.CALM as Status,
    bitcoinUsd: null,
    bitcoinRub: null,
    tonUsd: null,
    tonRub: null,
    tetherUsd: null,
    tetherRub: null,
}

const ratesSlice = createSlice({
    name: 'rates',
    initialState,
    reducers: {
        setRates(state, action) {
            const { tonUsd, tonRub, bitcoinUsd, bitcoinRub, tetherUsd, tetherRub } = action.payload;
            state.statusRates = Status.LOADING;
            state.tonRub = tonRub;
            state.tonUsd = tonUsd;
            state.tetherRub = tetherRub;
            state.tetherUsd = tetherUsd; 
            state.bitcoinRub = bitcoinRub;
            state.bitcoinUsd = bitcoinUsd;
            state.statusRates = Status.SUCCESS;
        },
    },
})

export const { setRates } = ratesSlice.actions;

export default ratesSlice.reducer;