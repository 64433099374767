import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { CurrencySliceState } from './types';


const initialState: CurrencySliceState = {
    currency: null,
    messageCurrency: '',
    statusCurrency: Status.CALM as Status,
}

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrency(state, action) {
            state.currency = action.payload.currency;
        }
    },
})

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;