import style from './CurrencyInfo.module.scss';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Images
import tonImg from '../../assets/img/wallet/ton.svg';
import tetherImg from '../../assets/img/wallet/tether.svg';
import bitcoinImg from '../../assets/img/wallet/bitcoin.svg';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import WalletAction from '../../components/walletAction/WalletAction';
import Transactions from '../../components/transaction/Transactions';

// Redux
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectRates } from '../../redux/rates/selectors';
import { selectWallets } from '../../redux/wallets/selectors';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { setBtcBalance, setTetherBalance, setTonBalance } from '../../redux/wallets/slice';

// Http
import { API_SERVER_WEBSOCKET } from '../../http';
import { setRates } from '../../redux/rates/slice';
import { Spin } from 'antd';
import { Status } from '../../utils/utils';


function getCryptoAmount(cryptoName: string | undefined,
                         ton_crypto: number | undefined,
                         btc_crypto: number | undefined,
                         usdt_crypto: number | undefined) {
    let balance;

    switch (cryptoName) {
        case 'ton':
            balance = ton_crypto;
            break;
        case 'btc':
            balance = btc_crypto;
            break;
        case 'usdt':
            balance = usdt_crypto;
            break;
        default:
            balance = null;
    }
    return balance;
}

function getCryptoRubAmount(cryptoName: string | undefined,
                            tonUsd: number | null,
                            bitcoinRub: number | null,
                            tetherRub: number | null) {
    let balance;

    switch (cryptoName) {
        case 'ton':
            balance = tonUsd;
            break;
        case 'btc':
            balance = bitcoinRub;
            break;
        case 'usdt':
            balance = tetherRub;
            break;
        default:
            balance = null;
    }
    return balance;
}



const CurrencyInfo = () => {
    const navigate = useNavigate();
    let { cryptoName } = useParams();

    // Redux
    const dispatch = useAppDispatch();

    // Get Wallets
    const { status, ton_wallet, btc_wallet, usdt_wallet } = useSelector(selectWallets);

    // Socket Get Rates
    const { tonRub, tetherRub, bitcoinRub } = useSelector(selectRates);


    const activeImgCrypto = cryptoName === 'ton' ? tonImg : cryptoName === 'btc' ? bitcoinImg : tetherImg;

    const balance = getCryptoAmount(cryptoName, ton_wallet?.balance_crypto, btc_wallet?.balance_crypto, usdt_wallet?.balance_crypto);
    const balanceCrypto = balance === 0 ? 0 : balance?.toFixed(4);

    const balanceRub = getCryptoRubAmount(cryptoName, tonRub, bitcoinRub, tetherRub);
    const balanceRuble = balanceRub === 0 ? 0 : balance?.toFixed(2);

    const token = localStorage.getItem('access_token');

    // Get Balance Bitcoin
    const WS_BTC_BALANCE_URL = `${API_SERVER_WEBSOCKET}/bitcoin/balance/ws`;
    const { lastJsonMessage: lastJsonMessageBtcBalance, readyState: readyStateBtcBalance } = useWebSocket(
        WS_BTC_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 40000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateBtcBalance === ReadyState.OPEN) {
            if (lastJsonMessageBtcBalance && typeof lastJsonMessageBtcBalance === 'string') {
                const balanceBtn = JSON.parse(lastJsonMessageBtcBalance);
                dispatch(setBtcBalance({
                    address: balanceBtn['address'],
                    balance_usd: balanceBtn['balance_usd'],
                    balance_crypto: balanceBtn['balance_crypto'],
                }))
            }
        }
    }, [readyStateBtcBalance, lastJsonMessageBtcBalance, dispatch])

    // Get Balance Ton
    const WS_TON_BALANCE_URL = `${API_SERVER_WEBSOCKET}/ton/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTonBalance, readyState: readyStateTonBalance } = useWebSocket(
        WS_TON_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTonBalance === ReadyState.OPEN) {
            if (lastJsonMessageTonBalance && typeof lastJsonMessageTonBalance === 'string') {
                const balanceTon = JSON.parse(lastJsonMessageTonBalance);
                dispatch(setTonBalance({
                    address: balanceTon['address'],
                    balance_usd: balanceTon['balance_usd'],
                    balance_crypto: balanceTon['balance_crypto'],
                }))
            }
        }
    }, [readyStateTonBalance, lastJsonMessageTonBalance, dispatch])

    // Get Balance Tether
    const WS_TETHER_BALANCE_URL = `${API_SERVER_WEBSOCKET}/usdt/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTetherBalance, readyState: readyStateTetherBalance } = useWebSocket(
        WS_TETHER_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTetherBalance === ReadyState.OPEN) {
            if (lastJsonMessageTetherBalance && typeof lastJsonMessageTetherBalance === 'string') {
                const balanceTether = JSON.parse(lastJsonMessageTetherBalance);
                dispatch(setTetherBalance({
                    address: balanceTether['address'],
                    balance_usd: balanceTether['balance_usd'],
                    balance_crypto: balanceTether['balance_crypto'],
                }))
            }
        }
    }, [readyStateTetherBalance, lastJsonMessageTetherBalance, dispatch])



    // Get Rates
    const WS_RATE_URL = `${API_SERVER_WEBSOCKET}/rate/ws`;
    const { lastJsonMessage: lastJsonMessageRate, readyState: readyStateRate } = useWebSocket(
        WS_RATE_URL,
        {
            share: false,
            reconnectInterval: 2000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyStateRate === ReadyState.OPEN) {
            if (lastJsonMessageRate && typeof lastJsonMessageRate === 'string') {
                const rates = JSON.parse(lastJsonMessageRate);
                dispatch(setRates({
                    tonUsd: rates['the-open-network/usd']?.rate,
                    tonRub: rates['the-open-network/rub']?.rate,
                    bitcoinUsd: rates['bitcoin/usd']?.rate,
                    bitcoinRub: rates['bitcoin/rub']?.rate,
                    tetherUsd: rates['tether/usd']?.rate,
                    tetherRub: rates['tether/rub']?.rate,
                }));
            }
        }
    }, [readyStateRate, lastJsonMessageRate, dispatch])

    return (
        <div className={style.currency_info}>
            <BackButton onClick={() => navigate(-1)} />
            <div className={style.currency_info__header}>
                <img src={activeImgCrypto} alt="crypto-img" />
                <div className={style.currency_info__name}>
                    <p>{cryptoName?.toUpperCase()} Balance</p>
                </div>
                <div className={style.currency_info__amount}>
                    {status === Status.LOADING
                        ? <Spin />
                        : (
                            <p>{cryptoName === 'btc' && btc_wallet && bitcoinRub
                                ? (btc_wallet?.balance_crypto * bitcoinRub).toFixed(2)
                                : cryptoName === 'ton' && ton_wallet && tonRub
                                    ? (ton_wallet?.balance_crypto * tonRub).toFixed(2)
                                    : usdt_wallet && tetherRub && (usdt_wallet?.balance_crypto * tetherRub).toFixed(2)}
                            </p>
                        )}
                </div>
                <div className={style.currency_info__amount_coin}>
                    <p>{balanceCrypto} {cryptoName?.toUpperCase()}</p>
                </div>
            </div>
            <WalletAction cryptoName={cryptoName} />
            <Transactions currency={cryptoName} />
        </div>
    )
}

export default CurrencyInfo;