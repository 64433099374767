import style from './Step3.module.scss';

// React
import { useEffect, useState } from 'react';

// Disbound
import { useDebounce } from "use-debounce";

// Ant Design
import { message as antdMessage } from 'antd';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { API_URL } from '../../../http';
import { useSelector } from 'react-redux';
import { Status, validateAddress } from '../../../utils/utils';
import { useAppDispatch } from '../../../redux/store';
import { selectQrCodes } from '../../../redux/qr_codes/selectors';
import { requestGetQrCodeBitcoin, requestGetQrCodeTon, requestGetQrCodeUsdt } from '../../../redux/qr_codes/asyncActions';

// Components
import Loading from '../../../components/spinner/Spinner';
import { CopyOutlined } from '@ant-design/icons';


const Step3 = () => {
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [debouncedValueAddress] = useDebounce(address, 300);

    let { type, cryptoName, action } = useParams();

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Qr Codes
    const { ton_qr_code, btc_qr_code, usdt_qr_code, messageQrCode, statusQrCode } = useSelector(selectQrCodes);

    useEffect(() => {
        if (cryptoName?.toUpperCase() === 'BTC') {
            if (!btc_qr_code && messageQrCode.length <= 0) {
                dispatch(requestGetQrCodeBitcoin());
            }
        } else if (cryptoName?.toUpperCase() === 'TON') {
            if (!ton_qr_code && messageQrCode.length <= 0) {
                dispatch(requestGetQrCodeTon());
            }
        } else if (cryptoName?.toUpperCase() === 'USDT') {
            if (!usdt_qr_code && messageQrCode.length <= 0) {
                dispatch(requestGetQrCodeUsdt());
            }
        }
    }, [dispatch, cryptoName, btc_qr_code, ton_qr_code, usdt_qr_code])

    useEffect(() => {
        if (messageQrCode.length > 0 && statusQrCode === Status.ERROR) {
            messageApi.error(messageQrCode, 2);
        }
    }, [messageQrCode, type])


    // Check Address
    const [isErrorAddress, setIsErrorAddress] = useState<boolean | null>(null);

    useEffect(() => {
        if (cryptoName === 'btc' && debouncedValueAddress.length > 0) {
            const checkBtcAddress = validateAddress(debouncedValueAddress, cryptoName);
            setIsErrorAddress(checkBtcAddress);
        } else if (cryptoName === 'usdt' && debouncedValueAddress.length > 0) {
            const checkUsdtAddress = validateAddress(debouncedValueAddress, cryptoName);
            setIsErrorAddress(checkUsdtAddress);
        } else if (cryptoName === 'ton' && debouncedValueAddress.length > 0) {
            const checkTonAddress = validateAddress(debouncedValueAddress, cryptoName);
            setIsErrorAddress(checkTonAddress);
        }
    }, [debouncedValueAddress, isErrorAddress])


    useEffect(() => {
        if (!isErrorAddress && debouncedValueAddress.length > 0 && cryptoName !== 'undefined') {
            messageApi.error(`Неправильный ${cryptoName?.toUpperCase()} адрес`, 1);
        }
    }, [isErrorAddress, debouncedValueAddress, messageApi])

    // Copy Wallet
    const clickBoardWallet = (e: any, wallet: any) => {
        e.preventDefault();
        try {
            // Creating a temporary text field
            const textArea = document.createElement('textarea');
            textArea.value = wallet;
            document.body.appendChild(textArea);

            // Selecting text in a field
            textArea.select();
            textArea.setSelectionRange(0, 99999); // Mobile Devices

            try {
                // Copy Text to clipboard
                const successful = document.execCommand('copy');
                const msg = successful ? 'Ссылка успешно скопирована' : 'Произошла ошибка при копировании ссылки';
                messageApi.info(msg, 2);
            } catch (err) {
                messageApi.error('Произошла ошибка при копировании ссылки', 2);
            }

            // Delete temporary text field
            document.body.removeChild(textArea);

        } catch (error) {
            messageApi.error('Произошла ошибка при копировании кошелька', 2);
        }
    }

    const handleToStep4 = () => {
        navigate(`/home/add-money/${type}/${cryptoName}/${action}/${address}/step4`);
    }

    return (
        <div className={style.step3}>
            {contextHolder}
            <BackButton onClick={() => navigate(-1)} />
            <h2>{type === 'top-up' ? 'Пополнить' : 'Отправить'}</h2>
            <div className={style.step3__content}>
                {type === 'top-up'
                    ? (
                        statusQrCode === Status.LOADING
                            ? <Loading />
                            : (
                                <div className={style.step3__content__qr_code}>
                                    <img
                                        src={`${API_URL}${cryptoName?.toUpperCase() === 'TON' ? ton_qr_code?.qr_code : cryptoName?.toUpperCase() === 'BTC' ? btc_qr_code?.qr_code : usdt_qr_code?.qr_code}`}
                                        alt="qr-code"
                                    />
                                    <div
                                        className={style.step3__content__copy}
                                        onClick={(e) => clickBoardWallet(e, cryptoName === 'btc'
                                            ? btc_qr_code?.address
                                            : cryptoName === 'ton'
                                                ? ton_qr_code?.address
                                                : usdt_qr_code?.address)}

                                    >
                                        <CopyOutlined />
                                        <p>{cryptoName?.toUpperCase() === 'TON' ? ton_qr_code?.address : cryptoName?.toUpperCase() === 'BTC' ? btc_qr_code?.address : usdt_qr_code?.address}</p>
                                    </div>
                                </div>
                            )
                    )
                    : (
                        <input
                            className={isErrorAddress === false ? `${style.error_input}` : ''}
                            type="text"
                            value={address}
                            placeholder='Введите адрес кошелька'
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    )}
            </div>

            {type === 'send' && (
                <div className={style.step3__btn}>
                    <button
                        onClick={() => handleToStep4()}
                        disabled={address.length === 0 || !isErrorAddress ? true : false} className={address.length === 0 || !isErrorAddress ? style.disabled : ''}
                    >Отправить</button>
                </div>
            )}
        </div>
    )
}

export default Step3;