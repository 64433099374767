export enum Status {
    CALM = 'calm',
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

type CurrencyType = {
    id: number;
    href: string;
    title: string;
    explorer: string;
    currency: string;
}

export function validateAddress(address: string, currency: string): boolean {
    const bitcoinOldRegex = /^(1|3)[a-zA-Z0-9]{25,34}$/; // Проверка для старых форматов Bitcoin
    const bitcoinNewRegex = /^bc1([a-zA-Z0-9]{39,59})$/; // Проверка для новых форматов Bitcoin (Bech32)
    const usdtRegex = /^T[a-zA-Z0-9]{33}$/; // Проверка для USDT (TRC20)
    const tonRegex = /^[A-Za-z0-9_-]{43,64}$/; // Проверка для TON

    switch (currency) {
        case 'btc':
            return bitcoinOldRegex.test(address) || bitcoinNewRegex.test(address);
        case 'usdt':
            return usdtRegex.test(address);
        case 'ton':
            return tonRegex.test(address);
        default:
            return false;
    }
}