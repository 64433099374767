import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { QrCodesSliceState } from './types';
import { requestGetQrCodeTon, requestGetQrCodeBitcoin, requestGetQrCodeUsdt } from './asyncActions';


const initialState: QrCodesSliceState = {
    messageQrCode: '',
    ton_qr_code: null,
    btc_qr_code: null,
    usdt_qr_code: null,
    statusQrCode: Status.CALM as Status,
}

const qrCodeSlice = createSlice({
    name: 'qrCodes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Ton QR Code
        builder.addCase(requestGetQrCodeTon.pending, (state) => {
            state.statusQrCode = Status.LOADING;
        })
        builder.addCase(requestGetQrCodeTon.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusQrCode = Status.SUCCESS;
                state.ton_qr_code = action.payload;
            } else {
                state.statusQrCode = Status.ERROR;
                state.messageQrCode = 'Произошла ошибка при получении TON QR Code, попробуйте позже';
            }
        })
        builder.addCase(requestGetQrCodeTon.rejected, (state) => {
            state.statusQrCode = Status.ERROR;
            state.messageQrCode = 'Произошла ошибка при получении TON QR Code, попробуйте позже';
        })
        // Get Bitcoin QR Code
        builder.addCase(requestGetQrCodeBitcoin.pending, (state) => {
            state.statusQrCode = Status.LOADING;
        })
        builder.addCase(requestGetQrCodeBitcoin.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusQrCode = Status.SUCCESS;
                state.btc_qr_code = action.payload;
            } else {
                state.statusQrCode = Status.ERROR;
                state.messageQrCode = 'Произошла ошибка при получении BTC QR Code, попробуйте позже';
            }
        })
        builder.addCase(requestGetQrCodeBitcoin.rejected, (state) => {
            state.statusQrCode = Status.ERROR;
            state.messageQrCode = 'Произошла ошибка при получении BTC QR Code, попробуйте позже';
        })
        // Get USDT QR Code
        builder.addCase(requestGetQrCodeUsdt.pending, (state) => {
            state.statusQrCode = Status.LOADING;
        })
        builder.addCase(requestGetQrCodeUsdt.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusQrCode = Status.SUCCESS;
                state.usdt_qr_code = action.payload;
            } else {
                state.statusQrCode = Status.ERROR;
                state.messageQrCode = 'Произошла ошибка при получении USDT QR Code, попробуйте позже';
            }
        })
        builder.addCase(requestGetQrCodeUsdt.rejected, (state) => {
            state.statusQrCode = Status.ERROR;
            state.messageQrCode = 'Произошла ошибка при получении USDT QR Code, попробуйте позже';
        })
    }
})

export default qrCodeSlice.reducer;