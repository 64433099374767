import { createAsyncThunk } from "@reduxjs/toolkit";

import WalletsService from "../../services/WalletsService";


export const requestGetQrCodeTon = createAsyncThunk('/ton/wallet/qr_code', async () => {
    const response = await WalletsService.getQrCodeTon();
    return response;
})

export const requestGetQrCodeBitcoin = createAsyncThunk('/bitcoin/wallet/qr_code', async () => {
    const response = await WalletsService.getQrCodeBtc();
    return response;
})

export const requestGetQrCodeUsdt = createAsyncThunk('/usdt/wallet/qr_code', async () => {
    const response = await WalletsService.getQrCodeUsdt();
    return response;
})
