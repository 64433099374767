import style from './Step2.module.scss';

// Number
import ReactCodeInput from 'react-code-input';


const Step2Verify = () => {
    return (
        <div className={style.step2_verification}>
            <h2>Введите код подтверждения</h2>
            <div className={style.step2_verification__info}>
                <p>Вам отправлен код на номер телефона</p>
                <p>+7 (999) 333-23-23</p>
            </div>
            <div className={style.step2_verification__code}>
                <ReactCodeInput type='number' fields={4} name={'phone_number'} inputMode={'tel'} />
            </div>
            <div className={style.step2_verification__info_repeat}>
                <p>Отправить код снова можно через 45 секунд</p>
            </div>
            <div className={style.step2_verification__btn}>
                <button>Подтвердить</button>
            </div>
        </div>
    )
}

export default Step2Verify;