import style from './Transactions.module.scss';


type Props = {
    currency: string | undefined;
}

const Transactions = ({ currency }: Props) => {
    return (
        <div className={style.currency_info__transactions}>
            <h2>История Транзакций</h2>
            <div className={style.currency_info__transactions__items}>
                <div className={style.transaction_add}>
                    <div className={style.transaction_add__left}>
                        <p className={style.transaction_title}>Пополнение</p>
                        <p className={style.transaction_date}>08.11.2024 в 12:48</p>
                    </div>
                    <div className={style.transaction_right}>
                        <p className={style.transaction_add_coint}>+10 {currency?.toUpperCase()}</p>
                        <p className={style.transaction_add_rub}>+2723 ₽</p>
                    </div>
                </div>

                <div className={style.transaction_transfer}>
                    <div className={style.transaction_add__left}>
                        <p className={style.transaction_title}>Перевод</p>
                        <p className={style.transaction_date}>08.11.2024 в 12:48</p>
                    </div>
                    <div className={style.transaction_right}>
                        <p className={style.transaction_add_coint}>-5 {currency?.toUpperCase()}</p>
                        <p className={style.transaction_add_rub}>-1246 ₽</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transactions;