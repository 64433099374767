// Router
import { Outlet } from "react-router-dom";

// Components
import Navbar from "../components/navbar/Navbar";

export function MainLayout() {
    return (
        <>
            <Outlet />
            <Navbar />
        </>
    )
}
