import style from './Step1.module.scss';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Images
import vnIcon from '../../assets/img/add_money/icon-vn.png';

// Router
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setCurrency } from '../../redux/currency/slice';
import { useParams, useNavigate } from 'react-router-dom';
import { selectCurrency } from '../../redux/currency/selectors';


const Step1 = () => {
    const navigate = useNavigate();
    let { type, cryptoName } = useParams();
    const { currency } = useSelector(selectCurrency);

    const handleToAddExternal = (type_: string) => {
        if (!currency && type === 'send') {
            navigate(`/home/add-money/${type}/${cryptoName}/${type_}/step2`);
        } else if (currency && type === 'send') {
            navigate(`/home/add-money/${type}/${cryptoName}/${type_}/step3`);
        } else if (!currency && type === 'top-up') {
            navigate(`/home/add-money/${type}/${cryptoName}/${type_}/step2`);
        } else {
            navigate(`/home/add-money/${type}/${cryptoName}/${type_}/step3`);
        }
    }

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrency({'currency': cryptoName === 'undefined' ? null : cryptoName}))
    }, [dispatch])

    return (
        <div className={style.add_money}>
            <BackButton onClick={() => navigate(-1)} />
            <h2>{type === 'top-up' ? 'Пополнить' : 'Отправить'}</h2>

            <div className={style.add_money__actions}>
                <h3>Как вы хотите {type === 'top-up' ? 'пополнить кошелёк' : 'отправить криптовалюту'}</h3>
            </div>
            <div className={style.add_money__choosed}>
                <div className={style.add_money__choosed__external_wallet} onClick={() => handleToAddExternal('external-wallet')}>
                    <div>
                        <img src={vnIcon} alt="card" />
                    </div>
                    <div className={style.add_money__choosed__card__bank_card}>
                        <p>Внешний кошелёк</p>
                        <p>{type === 'top-up' ? 'Перевести с другого кошелька' : 'Отправка на другой криптокошелёк'}</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Step1;