import style from './Step2.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { API_SERVER_WEBSOCKET } from '../../../http';
import { useAppDispatch } from '../../../redux/store';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { selectWallets } from '../../../redux/wallets/selectors';
import { setBtcBalance, setTetherBalance, setTonBalance } from '../../../redux/wallets/slice';

// Images
import tonImg from '../../../assets/img/wallet/ton.svg';
import tetherImg from '../../../assets/img/wallet/tether.svg';
import bitcoinImg from '../../../assets/img/wallet/bitcoin.svg';
import circleActive from '../../../assets/img/add_money/circle_active.png';
import circleInactive from '../../../assets/img/add_money/circle_inactive.png';


const Step2 = () => {
    const navigate = useNavigate();
    let { type, cryptoName, action } = useParams();
    const [activeCoin, setActiveCoin] = useState<number | null>(cryptoName === 'undefined' ? null : cryptoName === 'ton' ? 0 : cryptoName === 'usdt' ? 1 : 2);

    const handleTo = () => {
        const coin = activeCoin === 0 ? 'ton' : activeCoin === 1 ? 'usdt' : 'btc'; 
        navigate(`/home/add-money/${type}/${coin}/${action}/step3`);
    }

    // Redux
    const dispatch = useAppDispatch();

    // WebSockets Settings
    const token = localStorage.getItem('access_token');

    // Get Wallets
    const { ton_wallet, btc_wallet, usdt_wallet } = useSelector(selectWallets);

    // Get Balance Bitcoin
    const WS_BTC_BALANCE_URL = `${API_SERVER_WEBSOCKET}/bitcoin/balance/ws`;
    const { lastJsonMessage: lastJsonMessageBtcBalance, readyState: readyStateBtcBalance } = useWebSocket(
        WS_BTC_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateBtcBalance === ReadyState.OPEN) {
            if (lastJsonMessageBtcBalance && typeof lastJsonMessageBtcBalance === 'string') {
                const balanceBtn = JSON.parse(lastJsonMessageBtcBalance);
                dispatch(setBtcBalance({
                    address: balanceBtn['address'],
                    balance_usd: balanceBtn['balance_usd'],
                    balance_crypto: balanceBtn['balance_crypto'],
                }))
            }
        }
    }, [readyStateBtcBalance, lastJsonMessageBtcBalance, dispatch])

    // Get Balance Ton
    const WS_TON_BALANCE_URL = `${API_SERVER_WEBSOCKET}/ton/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTonBalance, readyState: readyStateTonBalance } = useWebSocket(
        WS_TON_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTonBalance === ReadyState.OPEN) {
            if (lastJsonMessageTonBalance && typeof lastJsonMessageTonBalance === 'string') {
                const balanceTon = JSON.parse(lastJsonMessageTonBalance);
                dispatch(setTonBalance({
                    address: balanceTon['address'],
                    balance_usd: balanceTon['balance_usd'],
                    balance_crypto: balanceTon['balance_crypto'],
                }))
            }
        }
    }, [readyStateTonBalance, lastJsonMessageTonBalance, dispatch])

    // Get Balance Tether
    const WS_TETHER_BALANCE_URL = `${API_SERVER_WEBSOCKET}/usdt/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTetherBalance, readyState: readyStateTetherBalance } = useWebSocket(
        WS_TETHER_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTetherBalance === ReadyState.OPEN) {
            if (lastJsonMessageTetherBalance && typeof lastJsonMessageTetherBalance === 'string') {
                const balanceTether = JSON.parse(lastJsonMessageTetherBalance);
                dispatch(setTetherBalance({
                    address: balanceTether['address'],
                    balance_usd: balanceTether['balance_usd'],
                    balance_crypto: balanceTether['balance_crypto'],
                }))
            }
        }
    }, [readyStateTetherBalance, lastJsonMessageTetherBalance, dispatch])

    return (
        <div className={style.choose_active}>
            <BackButton onClick={() => navigate(-1)} />
            <h2>{type === 'top-up' ? 'Пополнить' : 'Отправить'}</h2>
            <h3>Выберите актив для {type === 'top-up' ? 'получения' : 'отправки'}</h3>
            <div className={style.choose_active__coins}>
                <div onClick={() => setActiveCoin(0)} className={style.choose_active__coins_coin}>
                    <div className={style.choose_active__coins_coin__content}>
                        <img src={tonImg} alt="coin-img" />
                    </div>
                    <div className={style.choose_active__coins_coin__action}>
                        <div className={style.choose_active__coins_coin__action__text}>
                            <p>TON</p>
                            <p>{ton_wallet?.balance_crypto} TON</p>
                        </div>
                        <img src={activeCoin === 0 ? circleActive : circleInactive} alt="circle-inactive" />
                    </div>
                </div>

                <div onClick={() => setActiveCoin(1)} className={style.choose_active__coins_coin}>
                    <div className={style.choose_active__coins_coin__content}>
                        <img src={tetherImg} alt="coin-img" />
                    </div>
                    <div className={style.choose_active__coins_coin__action}>
                        <div className={style.choose_active__coins_coin__action__text}>
                            <p>USDT</p>
                            <p>{usdt_wallet?.balance_crypto} USDT</p>
                        </div>
                        <img src={activeCoin === 1 ? circleActive : circleInactive} alt="circle-inactive" />
                    </div>
                </div>

                <div onClick={() => setActiveCoin(2)} className={style.choose_active__coins_coin}>
                    <div className={style.choose_active__coins_coin__content}>
                        <img src={bitcoinImg} alt="coin-img" />
                    </div>
                    <div className={`${style.choose_active__coins_coin__action} ${style.choose_active__coins_coin__last}`}>
                        <div className={style.choose_active__coins_coin__action__text}>
                            <p>BTC</p>
                            <p>{btc_wallet?.balance_crypto} BTC</p>
                        </div>
                        <img src={activeCoin === 2 ? circleActive : circleInactive} alt="circle-active" />
                    </div>
                </div>
            </div>

            {type === 'top-up' && (
                <div className={style.choose_active__description}>
                    <p>Отправляйте только указанные монеты на этот адрес. Другие монеты или NFT будут безвозвратно утрачены</p>
                </div>
            )}

            <div className={style.choose_active__btn}>
                <button
                    onClick={() => handleTo()}
                    disabled={activeCoin === null && true}
                >Далее</button>
            </div>
        </div>
    )
}

export default Step2;