import { configureStore } from '@reduxjs/toolkit';
import type {TypedUseSelectorHook} from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import auth from './auth/slice';
import rates from './rates/slice';
import wallets from './wallets/slice';
import qrCodes from './qr_codes/slice';
import currency from './currency/slice';
import transaction from './transaction/slice';


export const store = configureStore({
    reducer: {
        auth,
        rates,
        wallets,
        qrCodes,
        currency,
        transaction,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;