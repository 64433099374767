import style from './WalletAction.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Images
import arrowUp from '../../assets/img/arrow_up.png';
import addCircle from '../../assets/img/add_circle.png';


type Props = {
    cryptoName: string | undefined;
}

const WalletAction = ({ cryptoName }: Props) => {
    const navigate = useNavigate();

    const handleTo = (type: string) => {
        navigate(`/home/add-money/${type}/${cryptoName}/step1`);
    }

    return (
        <div className={style.wallet__content}>
            <div className={style.wallet__content__send} onClick={() => handleTo('send')}>
                <img src={arrowUp} alt='icon-arrow-up' />
                <p>Отправить</p>
            </div>
            <div className={style.wallet__content__topup} onClick={() => handleTo('top-up')}>
                <img src={addCircle} alt='icon-add-circle' />
                <p>Пополнить</p>
            </div>
        </div>
    )
}

export default WalletAction;