import style from './Step1.module.scss';

// Images
import tinkoff from '../../../assets/img/tinkoff.png';
import gosUslugi from '../../../assets/img/gos_uslugi.png';


const Step1Verify = () => {
    return (
        <div className={style.step1_verification}>
            <h2>Пройдите верификацию KYC</h2>
            <div className={style.step1_verification__choose}>
                <p className={style.step1_verification__choose__subtitle}>С помощью</p>
                <div className={style.step1_verification__choose__icons}>
                    <div className={style.step1_verification__choose__icons__icon}>
                        <img src={tinkoff} alt="tinkoff-icon" />
                        <p>Т-банк</p>
                    </div>
                    <div className={style.step1_verification__choose__icons__icon}>
                        <img src={gosUslugi} alt="gosuslugi-icon" />
                        <p>Госуслуги</p>
                    </div>
                </div>
            </div>
            <div className={style.step1_verification__block_fields}>
                <p className={style.step1_verification__block_fields__subtitle}>Введите данные вручную</p>
                <div className={style.step1_verification__block_fields__form}>
                    <input type="text" placeholder='Номер телефона' />
                    <input type="text" placeholder='ФИО' />
                    <input type="text" placeholder='Дата рождения' />
                    <input type="text" placeholder='Пол' />
                </div>
                <div className={style.step1_verification__block_fields__btn}>
                    <button>Отправить</button>
                </div>
            </div>
        </div>
    )
}

export default Step1Verify;