import { createAsyncThunk } from '@reduxjs/toolkit';

import { TransactionFormData } from './types';
import TransactionService from '../../services/TransactionService';


export const requestTransactionCreateBitcoin = createAsyncThunk('/bitcoin/transaction/create', async (formData: TransactionFormData) => {
    const response = await TransactionService.transactionCreateBitcoin(formData);
    return response;    
})

export const requestTransactionCreateTon = createAsyncThunk('/ton/transaction/create', async (formData: TransactionFormData) => {
    const response = await TransactionService.transactionCreateTon(formData);
    return response;
})